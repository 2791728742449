// Fetch all.
export const FETCH_MANAGESHOW_ORDERS = 'FETCH_MANAGESHOW_ORDERS';
export const FETCH_MANAGESHOW_ORDERS_PENDING = 'FETCH_MANAGESHOW_ORDERS_PENDING';
export const FETCH_MANAGESHOW_ORDERS_REJECTED = 'FETCH_MANAGESHOW_ORDERS_REJECTED';
export const FETCH_MANAGESHOW_ORDERS_FULFILLED = 'FETCH_MANAGESHOW_ORDERS_FULFILLED';

export const UPDATE_MANAGESHOW_ORDER = 'UPDATE_MANAGESHOW_ORDER';
export const UPDATE_MANAGESHOW_ORDER_PENDING = 'UPDATE_MANAGESHOW_ORDER_PENDING';
export const UPDATE_MANAGESHOW_ORDER_REJECTED = 'UPDATE_MANAGESHOW_ORDER_REJECTED';
export const UPDATE_MANAGESHOW_ORDER_FULFILLED = 'UPDATE_MANAGESHOW_ORDER_FULFILLED';
