// SEND_ORDER_EMAIL
export const CANCEL_SEND_ORDER_EMAIL= 'CANCEL_SEND_ORDER_EMAIL';
export const REQUEST_SEND_ORDER_EMAIL= 'REQUEST_SEND_ORDER_EMAIL';
export const REQUEST_SEND_ORDER_EMAIL_PENDING = 'REQUEST_SEND_ORDER_EMAIL_PENDING';
export const REQUEST_SEND_ORDER_EMAIL_REJECTED = 'REQUEST_SEND_ORDER_EMAIL_REJECTED';
export const REQUEST_SEND_ORDER_EMAIL_FULFILLED = 'REQUEST_SEND_ORDER_EMAIL_FULFILLED';
export const SEND_ORDER_EMAIL= 'SEND_ORDER_EMAIL';
export const SEND_ORDER_EMAIL_PENDING = 'SEND_ORDER_EMAIL_PENDING';
export const SEND_ORDER_EMAIL_REJECTED = 'SEND_ORDER_EMAIL_REJECTED';
export const SEND_ORDER_EMAIL_FULFILLED = 'SEND_ORDER_EMAIL_FULFILLED';

